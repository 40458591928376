import React from 'react';

import Header from '../Header/Header';

import './PageWrapper.scss';

const classPrefix = 'tp-page-wrapper';

const PageWrapper = (props) => {
  return (
    <div className={classPrefix}>
      <Header />
      {props.children}
    </div>
  );
}

export default PageWrapper;
