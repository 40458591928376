import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PageWrapper from '../PageWrapper/PageWrapper';

import './ContactPage.scss';

const classPrefix = 'tp-contact-page';

class ContactPage extends React.Component {
  render() {
    return (
      <PageWrapper>
        <div className={`${classPrefix}`}>
          <Container maxWidth="lg">
            <div className={`${classPrefix}`}>
              <h2>Contact</h2>
              <p>
                If you have a project in mind or are simply interested in finding out more, get in touch and let’s get things moving.
              </p>
              <a
                className={`${classPrefix}__email`}
                href="mailto:timdpritchard@gmail.com"
                alt="timdpritchard@gmail.com">
                timdpritchard@gmail.com
              </a>
            </div>
            <Grid container className={`${classPrefix}__details`}>
              <Grid xs={6}>
                <h4>
                  Visit
                </h4>
                <p>
                  Oxford, England, OX1 3HA
                </p>
              </Grid>
              <Grid xs={6}>
                <h4>
                  Follow Me
                </h4>
                <p>
                  <a href="https://twitter.com/timmypritch" target="_blank" rel="noopener noreferrer">@timmypritch</a>
                </p>
              </Grid>
            </Grid>
          </Container>
          <div className={`${classPrefix}__map`} />
        </div>
      </PageWrapper>
    );
  };
}

export default ContactPage;
