// Home.js
import React from 'react';

import TitleBlock from '../TitleBlock/TitleBlock';
import PortfolioGallery from '../PortfolioGallery/PortfolioGallery';
import Companies from '../Companies/Companies';

import './Home.scss';

const classPrefix = "tp-home-page";

// <OurServices />
// <Companies />
class Home extends React.Component {
  render() {
    return (
      <div className={classPrefix}>
        <TitleBlock />
        <PortfolioGallery />
        <Companies />
      </div>
    );
  }
}

export default Home;
