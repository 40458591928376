import React from "react";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";

import logo from "../static/tp-logo3.svg";

import "./TitleBlock.scss";

const classPrefix = "tp-title-block";

const PAGES = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "About",
    href: "/about",
  },
  {
    name: "Contact",
    href: "/contact",
  },
];

export default function TitleBlock(props) {
  // <p className={`${classPrefix}__description`}>Frontend Web Developer</p>
  return (
    <section className={classPrefix}>
      <Container maxWidth="lg">
        <img className={`${classPrefix}__logo`} alt="logo" src={logo} />
        <h4 className={`${classPrefix}__name`}>
          Tim Pritchard<span className="highlight">.</span>
        </h4>
        <ul className={`${classPrefix}__pages`}>
          {PAGES.map(({ href, name }, index) => {
            return (
              <li key={index} className={`${classPrefix}__page-link`}>
                <Link to={href} alt={name}>
                  {name}
                  <span>.</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </Container>
    </section>
  );
}
