/* global window */

// configureStore.js
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';

export const history = createBrowserHistory();

const reduxDevTools = () => {
  const isDev = process.env.NODE_ENV === 'development';
  if (
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    isDev &&
    window.navigator.userAgent.includes('Chrome')
  ) return window.__REDUX_DEVTOOLS_EXTENSION__();

  return compose;
}

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
      ),
      reduxDevTools(),
    ),
  );

  return store;
}
