import CloudPianoWeb from "../static/posts/cloudpiano-web.png";
import MusieWeb from "../static/posts/musie-web.png";
import MusieDesign from "../static/posts/musie-design.png";
import MusieMarketing from "../static/posts/musie-marketing.png";
import FastingFoodz from "../static/posts/ff-design.png";
// import MelodyBookWeb from '../static/posts/melodybook-web.png';
import SainsburysWeb from "../static/posts/sainsburys-web.png";
import BBCIplayerWeb from "../static/posts/bbc-iplayer-web.jpg";
import BBCWeatherWeb from "../static/posts/bbc-weather-web.png";
import EmptyImg from "../static/posts/empty.png";

const cloudPiano = {
  id: "cloud-piano",
  description:
    "A real-time music game that allows users to learn piano and play with their friends.",
  type: "WEB APP",
  image: CloudPianoWeb,
};

const EMPTYOBJ = {
  id: "empty",
  description: "Get in touch and let me know what work you require doing.",
  type: "WEB/UX",
  image: EmptyImg,
};

const musieW = {
  id: "musie-web", // links with 'post' id
  description:
    "An online platform that helps users learn and practice music theory in a fun and interactive environment.",
  type: "WEB APP",
  image: MusieWeb,
};

// const melodybookW = {
//   id: "melodybook-web",
//   description: "An application that allows users to share and playback midi files. The goal is to create a platform in which melodies can be easily shared and learnt without needing to know music theory.",
//   type: "WEB APP",
//   image: MelodyBookWeb,
// };

const musieM = {
  id: "musie-marketing",
  description:
    "This details some of the digital marketing of the Musie product. This includes: product video, business cards, and facebook advertising.",
  type: "MARKETING",
  image: MusieMarketing,
};

const musieD = {
  id: "musie-design",
  description:
    "This details the Musie design framework as well as some of the descisions made around user experience.",
  type: "UX DESIGN",
  image: MusieDesign,
};

const fastingfoodzD = {
  id: "ff-design",
  description:
    "Website and logo design for a fasting-based food products company.",
  type: "LOGO DESIGN",
  image: FastingFoodz,
};

const sainsburysW = {
  id: "sainsburys-web",
  description:
    "Content management front-end build and public-facing website amends.",
  type: "WEB",
  image: SainsburysWeb,
};

const iPlayerW = {
  id: "bbc-iplayer-web",
  description:
    "Move the existing large monolithic application to Node JS microservices. Also build new features, including personalised recommendations.",
  type: "WEB",
  image: BBCIplayerWeb,
};

const BBCWeatherW = {
  id: "bbc-weather-web",
  description:
    "Create new production-ready website front-end for the BBC as part of a transition away from the Met office.",
  type: "WEB",
  image: BBCWeatherWeb,
};

const orderedItems = [
  // melodybookW,
  cloudPiano,
  sainsburysW,
  BBCWeatherW,
  iPlayerW,
  musieW,
  fastingfoodzD,
  musieM,
  musieD,
  EMPTYOBJ,
];

const noOfColumns = 3;
const groupSize = Math.ceil(orderedItems.length / noOfColumns);

let firstItemIndex = 0;
let counter = -1;

// Map left to right ordered items to column structure
export const mappedItems = orderedItems.map((item) => {
  counter++;
  let itemIndex = firstItemIndex + counter * noOfColumns;

  if (itemIndex >= orderedItems.length) {
    counter = 0;
    firstItemIndex++;

    itemIndex = firstItemIndex + counter * noOfColumns;
  }

  if (counter >= groupSize - 1) {
    counter = -1;
    firstItemIndex++;
  }

  return orderedItems[itemIndex];
});

const groupAlignment = {
  1: "left",
  2: "center",
  3: "right",
};

const convertToColumns = (theseItems) => {
  let columns = [];
  let currentGroup = [];
  let groupNumber = 1;

  for (let i = 0; i < theseItems.length; i++) {
    const isLastItem = i === theseItems.length - 1;
    const thisItem = theseItems[i];

    thisItem.align = groupAlignment[groupNumber];
    currentGroup.push(thisItem);

    if (currentGroup.length >= groupSize || isLastItem) {
      columns.push(currentGroup);
      currentGroup = [];

      if (isLastItem) groupNumber++;
    }
  }

  return columns;
};

export const columns = convertToColumns(mappedItems);
