import React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import configureStore, { history } from './configureStore';
import App from './App/App';
import * as serviceWorker from './serviceWorker';

import './index.scss';

// The initial state of the app can be set on the server
const initialState = window.APP_STATE || {};

// We merge it with the initial state
const mergedInitialPersistedState = {
  ...initialState,
};

const store = configureStore(mergedInitialPersistedState);

const rootElement = document.getElementById('root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */ }
      <App />
    </ConnectedRouter>
  </Provider>, rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
