import React from 'react';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import PageWrapper from '../PageWrapper/PageWrapper';

import './AboutPage.scss';

const classPrefix = 'tp-about-page';

const designSkills = [
  {
    id: "sketch",
    name: "Sketch"
  },
  {
    id: "ps",
    name: "Adobe Photoshop"
  },
  {
    id: "ai",
    name: "Adobe Illustrator"
  },
  {
    id: "ae",
    name: "Adobe After Effects"
  },
  {
    id: "xd",
    name: "Adobe XD"
  }
];

const devSkills = [
  {
    id: "html",
    name: "HTML",
  },
  {
    id: "css",
    name: "CSS",
  },
  {
    id: "js",
    name: "Javascript",
  },
  {
    id: "react",
    name: "React JS",
  },
  {
    id: "node",
    name: "Node JS"
  }
];

class AboutPage extends React.Component {
  render() {
    return (
      <PageWrapper>
        <Container maxWidth="lg">
          <div className={`${classPrefix}`}>
            <h2>About</h2>
            <p>
              Hey, I’m Tim. I’m a UI/UX developer living and working in the United Kingdom. I have over 6 years commercial experience in the role ranging from small start-ups to large corporations, including work for clients such as the British Broadcasting Corporation (BBC) and Sainsburys (UK-based supermarket chain).
              <br/>
              <br/>
              As a UI/UX developer I have helped clients design and implement production-ready websites and web applications that receive up to millions of visitors every month. This includes aiding the formation of designs - through prototying and establishing design frameworks, as well as implementing the final product. Below are some of the tools I use.
            </p>
            <h4>Development Tools</h4>
            <ul>
              {devSkills.map((devSkill, index) => {
                return (
                  <li key={index} className={`${classPrefix}__skill`}>
                    <Tooltip title={devSkill.name}>
                      <img src={`/icons/skill-icon-${devSkill.id}.svg`} alt={devSkill.name} />
                    </Tooltip>
                  </li>
                );
              })}
            </ul>
            <h4>Design/Prototyping Tools</h4>
            <ul>
              {designSkills.map((designSkill, index) => {
                return (
                  <li key={index} className={`${classPrefix}__skill`}>
                    <Tooltip title={designSkill.name}>
                      <img src={`/icons/skill-icon-${designSkill.id}.svg`} alt={designSkill.name} />
                    </Tooltip>
                  </li>
                );
              })}
            </ul>
          </div>
        </Container>
      </PageWrapper>
    );
  };
}

export default AboutPage;
