function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

const twitterLinkHost = "https://twitter.com/intent/tweet?text=";

export const twitterLink = (shareLink) => {
  const message = `Check this out: ${shareLink}`;

  return `${twitterLinkHost}${htmlDecode(message)}`;
};
