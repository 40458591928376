import React, { useState } from 'react';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Snackbar from '@material-ui/core/Snackbar';
import Input from '@material-ui/core/Input';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';


import Modal from '../Modal/Modal';
import { twitterLink } from './constants';
import { host } from '../constants';

import './PortfolioItem.scss';

export const classPrefix = "tp-portfolio-item";

export default function MediaCard(props) {
  const [hasModal, setHasModal] = useState(false);
  const {
    id,
    description,
    type,
    image,
    align,
  } = props;
  const isEmpty = id === 'empty';
  const hrefLink = isEmpty ? '' : `/post/${id}`;

  const showModal = () => {
    setHasModal(true);
  };

  const facebookShare = (href) => {
    window.FB.ui({
      method: 'share',
      href
    }, function(response){});
  }

  const ShareModal = () => {
    const [copied, hasCopied] = useState(false);

    const handleClick = () => {
      const shareText = document.querySelector("#shareText");
      shareText.select();
      document.execCommand('copy');
      hasCopied(true);
    };

    const handleClose = () => {
      hasCopied(false);
    };

    const shareLink = `${host}${hrefLink}`;

    return (
      <>
        <Snackbar
          className="tp-snackbar"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={copied}
          onClose={handleClose}
          autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'Copied to clipboard',
          }}
          message={<span id="message-id">Copied!</span>}
        />
        <Modal open={hasModal} setOpen={setHasModal} className={`${classPrefix}__modal`}>
          <>
            <h2 className={`${classPrefix}__modal__header textCenter`}>Share options</h2>
            <div className={`${classPrefix}__modal__text-link`}>
              <Input value={shareLink} id="shareText" className={`${classPrefix}__modal__input`} />
              <Button color="primary" aria-label="copy to clipboard" onClick={handleClick}>
                <AssignmentIcon />
              </Button>
            </div>
            <div>
              <span
                data-href="https://developers.facebook.com/docs/plugins/"
                data-layout="button_count"
                data-size="large"
              >
                <IconButton
                  className="facebook"
                  aria-label="facebook"
                  onClick={() => facebookShare(shareLink)}
                >
                  <FacebookIcon />
                </IconButton>
              </span>
              <IconButton
                className="twitter"
                aria-label="twitter"
                id="twitter-wjs"
                href={twitterLink(shareLink)}
                data-size="large"
              >
                <TwitterIcon />
              </IconButton>
            </div>
          </>
        </Modal>
      </>
    );
  }

  return (
    <Card className={classNames(
      classPrefix,
      {
        [`${classPrefix}--align-${align}`]: !!align,
      }
    )}>
      <div className={`${classPrefix}__inner`}>
        {hasModal && <ShareModal />}
        <CardActionArea href={hrefLink}>
          <CardMedia
            className={`${classPrefix}__media`}
            image={image}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography variant="h5" component="h5" className={`${classPrefix}__type`}>
              {type}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={`${classPrefix}__desc`}>
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
        {!isEmpty && (
          <CardActions>
            <Button size="small" color="primary" onClick={() => showModal(hrefLink)}>
              Share
            </Button>
            <Button size="small" color="primary" href={hrefLink}>
              Learn More
            </Button>
          </CardActions>
        )}
      </div>
    </Card>
  );
}
