import React from 'react';
import Container from '@material-ui/core/Container';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const HideOnScroll = (props) => {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header = (props) => {
  return (
    <HideOnScroll {...props}>
      <AppBar>
        <Toolbar>
          <Container maxWidth="lg">
            <IconButton href="/" edge="start" color="inherit" aria-label="menu">
              <HomeIcon />
            </IconButton>
          </Container>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default Header;
