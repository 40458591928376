import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { ReactComponent as BehanceIcon } from '../static/behance.svg';
import { ReactComponent as DribbbleIcon } from '../static/dribbble.svg';
import InstagramIcon from '@material-ui/icons/Instagram';

export const socialLinks = [
  {
    link: 'https://www.instagram.com/timmypritch/',
    Icon: InstagramIcon,
  },
  {
    link: 'https://www.behance.net/timdpritch148f',
    Icon: BehanceIcon,
  },
  {
    link: 'https://dribbble.com/TimPritchard',
    Icon: DribbbleIcon,
  },
  {
    link: 'https://www.linkedin.com/in/tim-pritchard-6635305b/',
    Icon: LinkedInIcon,
  },
];
