import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';

import { socialLinks } from './constants';
import { email, shortSummary } from '../constants';

import './Footer.scss';

const classPrefix = 'tp-footer';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: blueGrey[800],
    color: window.theme.palette.white
  },
  gridItem: {
    [theme.breakpoints.down('xs')]: {
      padding: '12px 32px!important'
    }
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={`${classPrefix} ${classes.root}`}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <h2>Tim Pritchard</h2>
            <p>{shortSummary}</p>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <h2>Contact Information</h2>
            <ul className="list-unstyled">
              <li><Link to="#" className="py-2 d-block">Oxford, England</Link></li>
              <li><Link to="#" className="py-2 d-block">{email}</Link></li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <ul className="tp-footer__social list-unstyled float-md-left float-lft">
              {socialLinks.map(({ link, Icon }, index) => (
                <li className={`${classPrefix}__social-list`} key={index}>
                  <Fade duration={1000} delay={200*index} collapse bottom>
                    <a href={link} className={`${classPrefix}__social-list__link`} target="_blank" rel="noopener noreferrer">
                      <Icon />
                    </a>
                  </Fade>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12}>
            <p className={`${classPrefix}__copyright textCenter`}>Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved</p>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
