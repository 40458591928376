// Home.js
import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PortfolioItem from '../PortfolioItem/PortfolioItem';
import { columns } from './constants';

import './PortfolioGallery.scss';

const classPrefix = "tp-portfolio-gallery";

class PortfolioGallery extends React.Component {

  componentDidMount() {
    const transition = document.querySelector(`.${classPrefix}`);
    const endFunc = () => {
      transition.style.opacity = 1;
    };

    transition.addEventListener('webkitAnimationEnd', endFunc);
    transition.addEventListener('msAnimationEnd', endFunc);
    transition.addEventListener('animationend', endFunc);
  }

  render() {
    return (
      <section className={classPrefix}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {columns.map((items, index) => {
              return (
                <Grid xs={12/columns.length} className="desktop-only" key={index} item>
                  {items.map((item, index) => {
                    return (
                      <PortfolioItem {...item} key={index} />
                    );
                  })}
                </Grid>
              );
            })}
            {columns.map((items, index) => {
              return (
                items.map((item, index) => {
                  return (
                    <Grid xs={12} sm={6} className="mobile-only" key={index} item>
                      <PortfolioItem {...item} key={index} />
                    </Grid>
                  );
                })
              );
            })}
          </Grid>
        </Container>
      </section>
    );
  }
}

export default PortfolioGallery;
