// Post.js
import React, { createElement } from 'react';
import Container from '@material-ui/core/Container';
import Helmet from 'react-helmet';
import marksy from 'marksy';
import PageWrapper from '../PageWrapper/PageWrapper';

import { posts } from './posts';

import './Post.scss';

const classPrefix = 'tp-post';

class Post extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null
    };
  }

  // Markdown helper function
  getMarkup = (field) => {
    if (!field) return null;

    const compile = marksy({
        createElement,
        elements: {
          Img (props) {
            // eslint-disable-next-line
            return <img {...props} />
          },
          Span (props) {
            return <span {...props} />
          },
          A (props) {
            // eslint-disable-next-line
            return <a {...props} />
          },
        }
    });

    return compile(field).tree;
  };

  componentDidMount() {
    this.setState({ data: posts[this.props.match.params.postId] });
  }

  render() {
    const { data } = this.state;
    if (!data) return null;

    const content = this.getMarkup(data.longText);

    return (
      <PageWrapper>
        <Helmet title={data.title} />
        <div className={classPrefix}>
          <Container maxWidth="lg">
            {content}
          </Container>
        </div>
      </PageWrapper>
    );
  }
}

export default Post;
