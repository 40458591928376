import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import Home from '../Home/Home';
import AboutPage from '../AboutPage/AboutPage';
import ContactPage from '../ContactPage/ContactPage';
import ScrollToTopOnMount from './ScrollToTopOnMount';
// import Header from '../Header/Header';
import Post from '../Post/Post';
import Footer from '../Footer/Footer';
import ScrollTop from './ScrollTop';

import { getProp } from '../utils/get';
import { palette } from '../constants';

import './App.scss';

const theme = window.theme = createMuiTheme({
  palette: {
    type: 'light',
    text: {
      ...palette.text,
      primary: palette.primary,
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: palette.primary,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: palette.secondary,
    },
    // error: will use the default color
  },
  typography: {
    fontFamily: [
      'Work Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiToolbarBar: {
      minHeight: '0px',
    },
  },
});

const classPrefix = 'tp-app';

class App extends React.Component {

  render() {
    const { router } = this.props;
    const pathname = getProp(router, 'location.pathname');
    const strippedPathname = pathname.slice(1).replace('/', '-');
    const routeModifier = strippedPathname ? `${classPrefix}--other` : `${classPrefix}--home`;

    // <div className={`${classPrefix}__top-bar`}>
    // <Header {...this.props} />
    // <Toolbar id="back-to-top-anchor" />
    // </div>
    // <Route path="/post/:id" component={Post} />
    // <Route path="/contact" component={ContactPage} />
    return (
      <ThemeProvider theme={theme}>
        <ScrollToTopOnMount />
        <div className={`${classPrefix} ${routeModifier}`}>
          <CssBaseline />
          <span id="back-to-top-anchor" />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/contact" component={ContactPage} />
            <Route path="/post/:postId" component={Post} />
          </Switch>
          <Footer />
          <ScrollTop {...this.props} className={`${classPrefix}__fab`} style={{zIndex: 3}}>
            <Fab color="secondary" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
        </div>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    router: state.router,
  };
}

export default connect(mapStateToProps)(App);
