/* eslint import/no-webpack-loader-syntax: off */
import cloudPiano from "!raw-loader!./markdown/cloud-piano.md";
import musieWeb from "!raw-loader!./markdown/musie-web.md";
import musieDesign from "!raw-loader!./markdown/musie-design.md";
import musieMarketing from "!raw-loader!./markdown/musie-marketing.md";
import ffMarketing from "!raw-loader!./markdown/ff-design.md";
import melodyBookWeb from "!raw-loader!./markdown/melodybook-web.md";
import sainsburysWeb from "!raw-loader!./markdown/sainsburys-web.md";
import BBCIplayerWeb from "!raw-loader!./markdown/bbc-iplayer-web.md";
import BBCWeatherWeb from "!raw-loader!./markdown/bbc-weather-web.md";

export const posts = {
  "cloud-piano": {
    longText: cloudPiano,
    title: "CloudPiano - Web",
  },
  "musie-web": {
    longText: musieWeb,
    title: "Musie - Web",
  },
  "musie-design": {
    longText: musieDesign,
    title: "Musie - Design",
  },
  "musie-marketing": {
    longText: musieMarketing,
    title: "Musie - Design",
  },
  "ff-design": {
    longText: ffMarketing,
    title: "Fasting Foodz - Marketing",
  },
  "melodybook-web": {
    longText: melodyBookWeb,
    title: "MelodyBook - Web App",
  },
  "sainsburys-web": {
    longText: sainsburysWeb,
    title: "Sainsburys SL - Web",
  },
  "bbc-iplayer-web": {
    longText: BBCIplayerWeb,
    title: "BBC iPlayer - Web",
  },
  "bbc-weather-web": {
    longText: BBCWeatherWeb,
    title: "BBC Weather - Web",
  },
};
