import React from 'react';
import Container from '@material-ui/core/Container';
import BBCSvg from '../static/bbc-logo.svg';
import SainsburysSvg from '../static/sainsburys.svg';
import OxboticaSvg from '../static/oxbotica.svg';

import './Companies.scss';

const classPrefix = 'tp-companies';

class Companies extends React.Component {
  render() {
    return (
      <section className={`${classPrefix}`}>
        <Container maxWidth="lg">
          <h2>I've worked on projects for...</h2>
          <ul>
            <li>
              <img src={BBCSvg} alt="BBC" />
            </li>
            <li>
              <img src={SainsburysSvg} alt="Sainsburys" />
            </li>
            <li>
              <img src={OxboticaSvg} alt="Oxbotica" />
            </li>
          </ul>
        </Container>
      </section>
    );
  };
}

export default Companies;
