import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import './Modal.scss';

const classPrefix = "tp-modal";

export default function TransitionsModal({open, setOpen, children}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classPrefix}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`${classPrefix}__modal`}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={`${classPrefix}__paper`}>
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
